import Image from "next/image";

function Logo() {
  return (
    <Image
      height="76"
      width="304"
      src="/logo.svg"
      blurDataURL="/logo.svg"
      placeholder="blur"
      alt="Logo MCP"
    />
  );
}

export default Logo;

import { ReactNode } from "react";

import { Flex, Text } from "@chakra-ui/react";

type TitleAuthProps = {
  title?: string;
  subTitle?: string;
  textLink?: ReactNode;
  colorTitle?: string;
};

function TitleAuth({
  title,
  subTitle,
  textLink,
  colorTitle = "black.26",
}: TitleAuthProps) {
  return (
    <>
      <Text
        fontSize="2xl"
        fontWeight={700}
        m="48px 0 12px 0"
        color={colorTitle}
        textAlign="center"
        className="title"
      >
        {title}
      </Text>

      <Flex fontSize={18} fontWeight={400} justify="center">
        <Text color="black.8c">{subTitle}</Text>
        {textLink}
      </Flex>
    </>
  );
}

export default TitleAuth;

import { Flex, Link, Text } from "@chakra-ui/react";

function FooterAuth() {
  return (
    <Flex color="black.8c" gap={1}>
      <Link target="_blank" href={`${process.env.CORE_URL}/faqs-page`} color="primary">FAQs </Link>
      <Text m="0 6px">•</Text>
      <Link target="_blank" href={`${process.env.CORE_URL}/support-contact-us`} color="primary"> Support </Link>
      <Text m="0 6px">•</Text>
      <Link target="_blank" href={`${process.env.CORE_URL}/about-us`} color="primary"> About us</Link>
    </Flex>
  );
}

export default FooterAuth;

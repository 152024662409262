import { ReactNode } from "react";

import { Box, Flex, Link } from "@chakra-ui/react";
import Head from "next/head";
import NextLink from "next/link";
import { useRouter } from "next/router";

import FooterAuth from "./FooterAuth";
import Logo from "./Logo";

type LayoutAuthProps = {
  children?: ReactNode;
  title?: string;
  description?: string;
  url?: string;
  type?: string;
};

const HeadConfig = {
  siteTitle: "MCP Account",
  description: "description",
};

function LayoutAuth({ ...props }: LayoutAuthProps) {
  const router = useRouter();
  const isExternal = !!router.query?.is_external;

  const title = props.title
    ? `${props.title} - ${HeadConfig.siteTitle}`
    : HeadConfig.siteTitle;

  const description = props.description
    ? props.description
    : HeadConfig.description;

  return (
    <Box
      sx={isExternal ? {
        "img[src='/logo.svg']": {
          display: "none",
        },
        ".container": {
          padding: 0,
          width: "auto",
        },
        ".title": {
          marginTop: 0,
          fontSize: "2rem",
        },
        ".social-buttons": {
          display: "inline-flex",
          button: {
            margin: "0 .2rem",
          },
          ".button-label": {
            display: "none",
          },
          ".chakra-button__icon": {
            marginInlineEnd: 0,
          },
        },
        ".cta": {
          flexWrap: "wrap",
          a: {
            marginTop: "-.8rem",
            fontSize: "1rem",
          },
          button: {
            width: "100%",
            marginTop: "1.5rem",
            padding: "1.8rem 0",
            fontSize: "1.1rem",
          },
        },
        form: {
          width: "100%",
        },
        "form > div": {
          borderRadius: 0,
          border: "none",
          boxShadow: "none",
        },
      } : undefined}
    >
      <Head>
        <title>{title}</title>
        <meta content={description} name="description" />
      </Head>
      <Flex
        alignItems="center"
        direction="column"
        justifyContent="flex-start"
        w="388px"
        py={{ base: 10, lg: "100px" }}
        mx="auto"
        minH="100vh"
        overflowY="auto"
        className="container"
      >
        <Link as={NextLink} passHref href="/signin">
          <Logo />
        </Link>
        {props.children}
        <FooterAuth />
      </Flex>
    </Box>
  );
}

export default LayoutAuth;
